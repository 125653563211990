import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Statistic,
    message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import useSWR from 'swr';
import CountUp from 'react-countup';


const formatter = (value: any) => <CountUp end={value} separator="," preserveValue />;

type FieldType = {
    password?: string;
};

const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    if (!json.success) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
};

const PasswordManage = () => {
    const n = useNavigate();
    const [form] = useForm();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        const token = cookie.load('token');
        const password = form.getFieldValue('password');
        console.log(password);
        const payload = {
            password: password,
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/forePassword/edit', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
        } else {
            message.error(response.message);
        }
        setIsModalOpen(false);
        form.resetFields(['password']);
    };
    const handleCancel = () => {
        form.resetFields(['password']);
        setIsModalOpen(false);
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/getPasswordInfo',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Modal
                title='Edit Password'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name='basic'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete='off'>
                    <Form.Item<FieldType>
                        label='New Password'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <Row align={'middle'} justify={'center'} style={{ margin: '10px' }}>
                <Col xs={22} lg={12}>
                    <Card title='Password Management'>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic
                                    title='Cards'
                                    value={data.cards}
                                    formatter={formatter}
                                />
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    title='Temporary Passwords'
                                    value={data.availablePasswordCnt}
                                    formatter={formatter}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Space wrap>
                            <Button type='primary' onClick={showModal}>
                                Edit Password
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => {
                                    n('/TempPassword');
                                }}>
                                Temporary Password
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => {
                                    n('/CardPassword');
                                }}>
                                Card Manage
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => {
                                    n('/face');
                                }}>
                                Face Manage
                            </Button>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PasswordManage;
