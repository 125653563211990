import { Card, message } from 'antd';
import useSWR from 'swr';
import React from 'react';
import { Col, Row, Statistic } from 'antd';
import CountUp from 'react-countup';
import { FaTemperatureHigh, FaTemperatureLow } from 'react-icons/fa';


const fetcher = async (url: string) => {
    const res = await fetch(url,{
        headers: new Headers({
            'Access-Control-Allow-Origin': '*'
        }),
    });
    const json = await res.json();
    return json;
}

const formatter = (value: any) => <CountUp end={value} separator="," preserveValue />;

const TempHumid = () => {
    const { data, error, isLoading } = useSWR('https://bemfa.kanosaikou.cn/weather',fetcher, {refreshInterval: 0});
    if (error){
        message.error(error.message);
        return <div>failed to load</div>
    }
    if (isLoading) return <div>loading...</div>
    return (
        <Row align={'middle'} justify={'center'} style={{margin: '10px'}}>
            <Col xs={22} lg={12}>
                <Card title="Weather">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic title="Weather" className='weather_text' suffix ={data.data.text_day}  formatter={formatter}/>
                        </Col>
                        <Col span={8}>
                            <Statistic title="High" value={data.data.high} prefix={<FaTemperatureHigh />} suffix="℃" formatter={formatter}/>
                        </Col>
                        <Col span={8}>
                            <Statistic title="Low" value={data.data.low} prefix={<FaTemperatureLow />} suffix="℃" formatter={formatter}/>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default TempHumid;