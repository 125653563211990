import { Button, Col, Layout, Row, message } from 'antd';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import TempHumid from './Widgets/TempHumid';
import Live from './Widgets/Live'
import Weather from './Widgets/Weather';
import PasswordManage from './Widgets/PasswordManage';
import EnterLog from './Widgets/EnterLog';
import Warning from './Widgets/Warning';
import LLMCard from './Widgets/LLMCard';

const { Header } = Layout;

const fetcher = async (url: string) => {
    const token = cookie.load("token");
    const res = await fetch(url,{
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        }),
    });
    const json = await res.json();
    if (!(json.success)) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
}

const Dashboard = () => {
    const n = useNavigate();
    const onLogout = () =>{
        cookie.remove("token");
        message.info("退出成功");
        n('/login');
    }
    const { data, error, isLoading } = useSWR('https://iotapi.kanosaikou.cn/user',fetcher);
    if (error){
        message.error(error.message);
        return <div>failed to load</div>
    }
    if (isLoading) return <div>loading...</div>
    return (
        <>
            <Header style={{backgroundColor: "white"}}>
                <Row gutter={12}>
                    <Col flex = {'auto'} style={{fontSize: '20px'}}>Smart Lock</Col>
                    <Col style={{fontSize: '20px'}}>{data.results.user_name}</Col>
                    <Col>
                        <Button type="primary" onClick={onLogout}>Log out</Button>
                    </Col>
                </Row>
            </Header>
            <PasswordManage />
            <LLMCard />
            <EnterLog />
            <Warning />
            <Weather />
            <TempHumid />
            <Live />
        </>
    );
}

export default Dashboard;