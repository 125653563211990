import {
    Space,
    Button,
    Card,
    Col,
    Layout,
    Row,
    message,
    Divider,
    Form,
    Input,
    Modal,
    DatePicker,
} from 'antd';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import {
    EditOutlined,
    DeleteOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import dayjs from 'dayjs';
import './App.css';
const { Header } = Layout;

type FieldType = {
    password?: string;
    outtime?: string;
    id?: string;
};

const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    if (!json.success) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
};

const Detail = ({ data }: any) => {
    const [time, setTime] = React.useState(0);
    const { mutate } = useSWRConfig();
    const date = dayjs(new Date(data.outtime * 1000)).format(
        'YYYY-MM-DD HH:mm:ss'
    );
    const [form] = useForm();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const editModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        const token = cookie.load('token');
        const id = data.id;
        const password = form.getFieldValue('password');
        const payload = {
            password: password,
            outtime: time,
            id: id,
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/tempPassword/edit', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/tempPassword/get');
        } else {
            message.error(response.message);
        }
        setIsModalOpen(false);
        form.resetFields(['password', 'outtime']);
    };
    const handleCancel = () => {
        form.resetFields(['password', 'outtime']);
        setIsModalOpen(false);
    };
    const del = async () => {
        const token = cookie.load('token');
        const id = data.id;
        const payload = {
            id: id,
        };
        const result = await fetch(
            'https://iotapi.kanosaikou.cn/tempPassword/delete',
            {
                method: 'POST',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify(payload),
            }
        );
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/tempPassword/get');
        } else {
            message.error(response.message);
        }
    };
    return (
        <>
            <Modal
                title='Edit Temporary Password'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name='basic'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete='off'>
                    <Form.Item<FieldType>
                        label='Password'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label='Expires on'
                        name='outtime'
                        rules={[
                            {
                                required: true,
                                message: 'Please select expire time!',
                            },
                        ]}>
                        <DatePicker
                            style={{ width: '100%' }}
                            showTime
                            onChange={(value, dateString) => {
                                setTime(value?.unix());
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Row gutter={8} style={{ margin: '2px' }} align={'middle'}>
                <Col span={6}>{data.password}</Col>
                <Col flex={'auto'}>{date}</Col>
                <Col>
                    <Button
                        type='primary'
                        shape='circle'
                        icon={<EditOutlined />}
                        onClick={editModal}></Button>
                </Col>
                <Col>
                    <Button
                        type='primary'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        onClick={del}
                        danger></Button>
                </Col>
            </Row>
        </>
    );
};

const TempPasswordcard = () => {
    const n = useNavigate();
    const [form] = useForm();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [time, setTime] = React.useState(0);
    const { mutate } = useSWRConfig();
    const ShowModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        const token = cookie.load('token');
        const password = form.getFieldValue('password');
        const outtime = time;
        const payload = {
            password: password,
            outtime: outtime,
        };
        const result1 = await fetch('https://iotapi.kanosaikou.cn/tempPassword/add', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result1.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/tempPassword/get');
        } else {
            message.error(response.message);
        }
        setIsModalOpen(false);
        form.resetFields(['password', 'outtime']);
    };
    const handleCancel = () => {
        form.resetFields(['password', 'outtime']);
        setIsModalOpen(false);
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/tempPassword/get',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Modal
                title='Add Temporary Password'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name='basic'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete='off'>
                    <Form.Item<FieldType>
                        label='Password'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please input your temporary password!',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label='Expires on'
                        name='outtime'
                        rules={[
                            {
                                required: true,
                                message: 'Please select expire time!',
                            },
                        ]}>
                        <DatePicker
                            style={{ width: '100%' }}
                            showTime
                            onChange={(value, dateString) => {
                                setTime(value?.unix());
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Card
                title={
                    <Space>
                        <Button
                            type='text'
                            shape='circle'
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                n('/dashboard');
                            }}></Button>
                        {'Temporary Password'}
                    </Space>
                }
                extra={
                    <Button type='primary' onClick={ShowModal}>
                        Add
                    </Button>
                }>
                <Space
                    direction='vertical'
                    split={<Divider />}
                    style={{ width: '100%' }}>
                    <>
                        <Row
                            gutter={8}
                            style={{ margin: '2px', fontWeight: 'bold' }}
                            align={'middle'}>
                            <Col span={6}>Password</Col>
                            <Col flex={'auto'}>Expires on</Col>
                        </Row>
                        {data.results.map((item: any, key: any) => {
                            return <Detail data={item} key={item.id} />;
                        })}
                    </>
                </Space>
            </Card>
        </>
    );
};

const TempPassword = () => {
    const n = useNavigate();
    const onLogout = () => {
        cookie.remove('token');
        message.info('退出成功');
        n('/login');
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/user',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Header style={{ backgroundColor: 'white' }}>
                <Row gutter={12}>
                    <Col flex={'auto'} style={{ fontSize: '20px' }}>
                        Smart Lock
                    </Col>
                    <Col style={{ fontSize: '20px' }}>
                        {data.results.user_name}
                    </Col>
                    <Col>
                        <Button type='primary' onClick={onLogout}>
                            Log out
                        </Button>
                    </Col>
                </Row>
            </Header>
            <Row justify={'center'} style={{ marginTop: '10px' }}>
                <Col xs={22} lg={12}>
                    <TempPasswordcard />
                </Col>
            </Row>
        </>
    );
};

export default TempPassword;
