import { Card, Col, Row } from 'antd';
import React from 'react';
import ReactPlayer from 'react-player';



const Live = () => {
    return (
        <Row align={'middle'} justify={'center'} style={{margin: '10px'}}>
            <Col xs={22} lg={12}>
                <Card title="Real-time Monitoring" >
                    <Row align={'middle'} justify={'center'}>
                        <ReactPlayer url='https://iotlive.kanosaikou.cn/live/test/hls.m3u8' controls playing/>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default Live;