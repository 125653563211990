import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Register from './Register';
import Login from './login';
import Dashboard from './dashboard'
import TempPassword from './TempPassword'
import CardPassword from './CardPassword'
import ELog from './ELog';
import Warn from './Warn';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Face from './Face';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/TempPassword' element={<TempPassword />} />
        <Route path='/CardPassword' element={<CardPassword />} />
        <Route path='/ELog' element={<ELog />} />
        <Route path='/Warn' element={<Warn />} />
        <Route path='/face' element={<Face />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
