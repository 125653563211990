import React from 'react';
import type { FormProps } from 'antd';
import { Button, Card, Checkbox, Col, Form, Input, Row, message } from 'antd';
import cookie from 'react-cookies'
import { useNavigate } from 'react-router-dom';

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

const Login = () => {
    const n = useNavigate();
    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        console.log('Success:', values);
        const payload = {
            user_name: values.username,
            password: values.password
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/auth/login', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            const expires = new Date(new Date().getTime() + 60 * 3600 * 1000);
            cookie.save("token", response.token, { expires });
            n("/dashboard");
        } else {
            message.error(response.message);
        }
    };
    React.useEffect(() => {
        if (cookie.load("token")) {
            n('/dashboard');
        }
    }, []);
    return (
        <Row align={'middle'} justify={'center'} style={{ height: '100vh' }}>
            <Col xs={22} lg={12}>
                <Card title="Log in" extra={<a href='/register'>Register</a>}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item<FieldType>
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="remember"
                            valuePropName="checked"
                            wrapperCol={{ offset: 8, span: 16 }}
                        >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default Login;