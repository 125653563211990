import {
    Space,
    Button,
    Card,
    Col,
    Layout,
    Row,
    message,
    Divider,
    Form,
    Input,
    Modal,
    DatePicker,
    Image,
    Upload,
} from 'antd';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import {
    PlusOutlined,
    DeleteOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import dayjs from 'dayjs';
import './App.css';
const { Header } = Layout;

type FieldType = {
    name?: string;
};

const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    if (!json.success) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
};

const PersonCard = ({ item }: any) => {
    const { mutate } = useSWRConfig();
    const handleDelete = async () => {
        const token = cookie.load('token');
        const payload = {
            id: item.id,
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/face/delete', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/face/get');
        } else {
            message.error(response.message);
        }
    };
    const handleUpload = async (event: any) => {
        const token = cookie.load('token');
        const formData = new FormData();
        formData.append('files', event.file);
        formData.append('personId', item.id);
        const response = await (await fetch('https://iotapi.kanosaikou.cn/face/upload', {
            method: 'POST',
            body: formData,
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
            }),
        })).json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/face/getPics?id=' + item.id);
        } else {
            message.error(response.message);
        }
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/face/getPics?id=' + item.id,
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <Card
            title={item.name}
            extra={
                <Button
                    type='primary'
                    icon={<DeleteOutlined />}
                    onClick={handleDelete}
                    danger
                />
            }>
            <Space wrap size={'large'}>
                {data.faces.map((pic: any, index: any) => {
                    return <Image width={150} src={`https://iotapi.kanosaikou.cn/machine/uploads/${pic.address}`} />;
                })}
                <Upload
                    name='avatar'
                    listType='picture-card'
                    showUploadList={false}
                    customRequest={handleUpload}>
                    <button
                        style={{ border: 0, background: 'none' }}
                        type='button'>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                </Upload>
            </Space>
        </Card>
    );
};

const FaceCard = () => {
    const n = useNavigate();
    const [form] = useForm();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { mutate } = useSWRConfig();
    const ShowModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        const token = cookie.load('token');
        const name = form.getFieldValue('name');
        const payload = {
            name,
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/face/add', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/face/get');
        } else {
            message.error(response.message);
        }
        setIsModalOpen(false);
        form.resetFields(['name']);
    };
    const handleCancel = () => {
        form.resetFields(['name']);
        setIsModalOpen(false);
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/face/get',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Modal
                title='Add Person'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name='basic'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete='off'>
                    <Form.Item<FieldType>
                        label='Name'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Card
                title={
                    <Space>
                        <Button
                            type='text'
                            shape='circle'
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                n('/dashboard');
                            }}></Button>
                        {'Faces'}
                    </Space>
                }
                extra={
                    <Button type='primary' onClick={ShowModal}>
                        Add
                    </Button>
                }>
                {data.faces.map((item: any, index: any) => {
                    return <PersonCard item={item} key={item.name + index} />;
                })}
            </Card>
        </>
    );
};

const Face = () => {
    const n = useNavigate();
    const onLogout = () => {
        cookie.remove('token');
        message.info('退出成功');
        n('/login');
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/user',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Header style={{ backgroundColor: 'white' }}>
                <Row gutter={12}>
                    <Col flex={'auto'} style={{ fontSize: '20px' }}>
                        Smart Lock
                    </Col>
                    <Col style={{ fontSize: '20px' }}>
                        {data.results.user_name}
                    </Col>
                    <Col>
                        <Button type='primary' onClick={onLogout}>
                            Log out
                        </Button>
                    </Col>
                </Row>
            </Header>
            <Row justify={'center'} style={{ marginTop: '10px' }}>
                <Col xs={22} lg={12}>
                    <FaceCard />
                </Col>
            </Row>
        </>
    );
};

export default Face;
