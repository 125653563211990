import { Button, Card, Col, Row, Space, Statistic, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import cookie from 'react-cookies';
import useSWR from 'swr';
import type { StatisticProps } from 'antd';
import CountUp from 'react-countup';

const formatter: StatisticProps['formatter'] = (value) => (
  <CountUp end={value as number} separator="," />
);


const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    if (!json.success) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
};

const Warning = () => {
    const n = useNavigate();
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/warning/getnew',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <Row align={'middle'} justify={'center'} style={{ margin: '10px' }}>
            <Col xs={22} lg={12}>
                <Card
                    title='Warnings'
                    extra={
                        <Button
                            onClick={() => {
                                n('/Warn');
                            }}
                            icon={<MoreOutlined />}>
                            More Information
                        </Button>
                    }>
                    <Statistic
                        title='Unread Logs'
                        value={data.results[0].count}
                        formatter={formatter}
                        valueStyle={{ color: '#cf1322' }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default Warning;
