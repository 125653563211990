import {
    Space,
    Button,
    Card,
    Col,
    Layout,
    Row,
    message,
    Timeline,
} from 'antd';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { Typography, Image } from 'antd';
import dayjs from 'dayjs';
const { Header } = Layout;
const { Title } = Typography;

const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    if (!json.success) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
};

const Detail = ({ data }: any) => {
    const time = dayjs(new Date(data.time)).format('HH:mm:ss');
    return (
        <>
            <Row gutter={8}>
                <Col span={12}>
                    <Title
                        level={5}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            wordBreak: 'break-all',
                        }}>
                        {time}
                    </Title>
                </Col>
                <Col span={12}>
                    <Image src={`https://iotapi.kanosaikou.cn/machine/uploads/${data.address}`} />
                </Col>
            </Row>
        </>
    );
};

const CardPasswordcard = () => {
    const n = useNavigate();
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/warning/get',
        fetcher
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    let timeLineItems = [];
    let timeSplit = [];
    if (data.results.length > 0) {
        let lastIndex = 0;
        let lastTime = new Date(data.results[0].time).toLocaleDateString();
        for (let i = 1; i < data.results.length; i++) {
            let thisTime = new Date(data.results[i].time).toLocaleDateString();

            if (thisTime !== lastTime) {
                timeSplit = data.results.slice(lastIndex, i);
                console.log(timeSplit[0].time);
                timeLineItems.push({
                    children: (
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <Title
                                level={4}
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    wordBreak: 'break-all',
                                }}>
                                {new Date(
                                    timeSplit[0].time
                                ).toLocaleDateString()}
                            </Title>
                            {timeSplit.map((item: any, index: any) => {
                                return <Detail data={item} key={item.id} />;
                            })}
                        </Space>
                    ),
                });
                lastIndex = i;
                lastTime = thisTime;
            }
        }
        timeSplit = data.results.slice(lastIndex);
        timeLineItems.push({
            children: (
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Title
                        level={4}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            wordBreak: 'break-all',
                        }}>
                        {new Date(timeSplit[0].time).toLocaleDateString()}
                    </Title>
                    {timeSplit.map((item: any, index: any) => {
                        return <Detail data={item} key={item.id} />;
                    })}
                </Space>
            ),
        });
    }
    return (
        <>
            <Card
                title={
                    <Space>
                        <Button
                            type='text'
                            shape='circle'
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                n('/dashboard');
                            }}></Button>
                        {'Warnings'}
                    </Space>
                }>
                <Timeline items={timeLineItems}></Timeline>
            </Card>
        </>
    );
};

const Warn = () => {
    const n = useNavigate();
    const onLogout = () => {
        cookie.remove('token');
        message.info('退出成功');
        n('/login');
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/user',
        fetcher
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Header style={{ backgroundColor: 'white' }}>
                <Row gutter={12}>
                    <Col flex={'auto'} style={{ fontSize: '20px' }}>
                        Smart Lock
                    </Col>
                    <Col style={{ fontSize: '20px' }}>
                        {data.results.user_name}
                    </Col>
                    <Col>
                        <Button type='primary' onClick={onLogout}>
                            Log out
                        </Button>
                    </Col>
                </Row>
            </Header>
            <Row justify={'center'} style={{ marginTop: '10px' }}>
                <Col xs={22} lg={12}>
                    <CardPasswordcard />
                </Col>
            </Row>
        </>
    );
};

export default Warn;
