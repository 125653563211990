import { Card, message } from 'antd';
import useSWR from 'swr';
import React from 'react';
import { Col, Row } from 'antd';
import cookie from 'react-cookies';
import Markdown from './Markdown';

const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    return json;
};

const LLMCard = () => {
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/llm',
        fetcher,
        { refreshInterval: 0 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <Row align={'middle'} justify={'center'} style={{ margin: '10px' }}>
            <Col xs={22} lg={12}>
                <Card title='AI Tips'>
                    <Markdown content={data.result}></Markdown>
                </Card>
            </Col>
        </Row>
    );
};

export default LLMCard;
