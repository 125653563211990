import {
    Space,
    Button,
    Card,
    Col,
    Layout,
    Row,
    message,
    Divider,
    Form,
    Input,
    Modal,
} from 'antd';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import {
    EditOutlined,
    DeleteOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
const { Header } = Layout;

type FieldType = {
    cardid?: string;
    cardtag?: string;
    id?: string;
};

const fetcher = async (url: string) => {
    const token = cookie.load('token');
    const res = await fetch(url, {
        headers: new Headers({
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
        }),
    });
    const json = await res.json();
    if (!json.success) {
        const error = new Error((await json).message);
        throw error;
    }
    return json;
};

const Detail = ({ data }: any) => {
    const [form] = useForm();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { mutate } = useSWRConfig();
    const editModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        const token = cookie.load('token');
        const id = data.id;
        const cardtag = form.getFieldValue('cardtag');
        console.log(cardtag);
        const payload = {
            cardtag: cardtag,
            id: id,
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/cardPassword/edit', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/cardPassword/get');
        } else {
            message.error(response.message);
        }
        setIsModalOpen(false);
        form.resetFields(['cardtag']);
    };
    const handleCancel = () => {
        form.resetFields(['cardtag']);
        setIsModalOpen(false);
    };
    const del = async () => {
        const token = cookie.load('token');
        const id = data.id;
        const payload = {
            id: id,
        };
        const result = await fetch('https://iotapi.kanosaikou.cn/cardPassword/del', {
            method: 'POST',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(payload),
        });
        const response = await result.json();
        if (response.success) {
            message.success(response.message);
            mutate('https://iotapi.kanosaikou.cn/cardPassword/get');
        } else {
            message.error(response.message);
        }
    };
    return (
        <>
            <Modal
                title='Edit Card'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name='basic'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete='off'>
                    <Form.Item<FieldType>
                        label='Name'
                        name='cardtag'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your cardtag!',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <Row gutter={8} style={{ margin: '2px' }} align={'middle'}>
                <Col span={6}>{data.cardtag}</Col>
                <Col flex={'auto'}>{data.cardid}</Col>
                <Col>
                    <Button
                        type='primary'
                        shape='circle'
                        icon={<EditOutlined />}
                        onClick={editModal}></Button>
                </Col>
                <Col>
                    <Button
                        type='primary'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        onClick={del}
                        danger></Button>
                </Col>
            </Row>
        </>
    );
};

const CardPasswordcard = () => {
    const n = useNavigate();
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/cardPassword/get',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Card
                title={
                    <Space>
                        <Button
                            type='text'
                            shape='circle'
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                n('/dashboard');
                            }}></Button>
                        {'Card Password'}
                    </Space>
                }>
                <Space
                    direction='vertical'
                    split={<Divider />}
                    style={{ width: '100%' }}>
                    <>
                        <Row
                            gutter={8}
                            style={{ margin: '2px', fontWeight:'bold'}}
                            align={'middle'}>
                            <Col span={6}>Name</Col>
                            <Col flex={'auto'}>Card ID</Col>
                        </Row>
                        {data.results.map((item: any, key: any) => {
                            return <Detail data={item} key={item.id} />;
                        })}
                    </>
                </Space>
            </Card>
        </>
    );
};

const CardPassword = () => {
    const n = useNavigate();
    const onLogout = () => {
        cookie.remove('token');
        message.info('退出成功');
        n('/login');
    };
    const { data, error, isLoading } = useSWR(
        'https://iotapi.kanosaikou.cn/user',
        fetcher,
        { refreshInterval: 3000 }
    );
    if (error) {
        message.error(error.message);
        return <div>failed to load</div>;
    }
    if (isLoading) return <div>loading...</div>;
    return (
        <>
            <Header style={{ backgroundColor: 'white' }}>
                <Row gutter={12}>
                    <Col flex={'auto'} style={{ fontSize: '20px' }}>
                        Smart Lock
                    </Col>
                    <Col style={{ fontSize: '20px' }}>
                        {data.results.user_name}
                    </Col>
                    <Col>
                        <Button type='primary' onClick={onLogout}>
                            Log out
                        </Button>
                    </Col>
                </Row>
            </Header>
            <Row justify={'center'} style={{ marginTop: '10px' }}>
                <Col xs={22} lg={12}>
                    <CardPasswordcard />
                </Col>
            </Row>
        </>
    );
};

export default CardPassword;
